var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"pt-2 pb-0"},[_vm._v(_vm._s(_vm.propTitle))]),_c('v-card-text',[_c('v-data-table',{ref:_vm.propTitle.replace(' ', '') + 'tabela',attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"headers":_vm.filtroHeaders,"items":_vm.propItems.items,"dense":"","fixed-header":_vm.propFixedHeaders,"height":_vm.propHeight,"hide-default-footer":"","item-key":"id","header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.label === 'Vigente')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true)},[_c('span',[_vm._v(" São considerados contratos vigentes os que estão alocados em turmas, independente do status do turma, podendo ser necessário aprovar/reprovar em turmas concluídas. ")])]):_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])],1)]}},{key:"header.selected",fn:function(ref){
var header = ref.header;
return [_c('v-checkbox',{staticStyle:{"margin-top":"0px","padding-top":"0px"},attrs:{"dense":"","hide-details":""},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}})]}},_vm._l((_vm.$scopedSlots),function(x,slotName){return {key:slotName,fn:function(context){return [_vm._t(slotName,null,null,context)]}}}),{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticStyle:{"margin-top":"0px","padding-top":"0px"},attrs:{"dense":"","hide-details":""},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [(_vm.propItems.aggregate_function != '' && _vm.propAppend)?_c('tr',_vm._l((headers),function(h,idx){return _c('td',{key:idx},[(h.value == 'value' || h.value.toLowerCase().includes('valor'))?_c('b',[(_vm.propItems.aggregate_function == 'sum')?_c('b',[_vm._v(" "+_vm._s(h.value.includes('temp') ? _vm.sumSelectedField(_vm.propItems.items, h.text) : _vm.sumSelectedField(_vm.propItems.items, h.value))+" ")]):_vm._e()]):_vm._e()])}),0):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }